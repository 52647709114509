import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
};

/** A basic healthcheck query */
export type Healthcheck = {
  __typename?: 'Healthcheck';
  /** DB current timestamp */
  databaseTime: Scalars['ISO8601DateTime'];
  greeting: Scalars['String'];
  /** Server current timestamp */
  serverTime: Scalars['ISO8601DateTime'];
};


export type Mutation = {
  __typename?: 'Mutation';
  /** An example field added by the generator */
  greet: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  healthcheck: Healthcheck;
};

export type HealthcheckQueryVariables = Exact<{ [key: string]: never; }>;


export type HealthcheckQuery = { __typename?: 'Query', healthcheck: { __typename?: 'Healthcheck', greeting: string, serverTime: any, databaseTime: any } };


export const HealthcheckDocument = gql`
    query Healthcheck {
  healthcheck {
    greeting
    serverTime
    databaseTime
  }
}
    `;

/**
 * __useHealthcheckQuery__
 *
 * To run a query within a React component, call `useHealthcheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthcheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthcheckQuery({
 *   variables: {
 *   },
 * });
 */
export function useHealthcheckQuery(baseOptions?: Apollo.QueryHookOptions<HealthcheckQuery, HealthcheckQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthcheckQuery, HealthcheckQueryVariables>(HealthcheckDocument, options);
      }
export function useHealthcheckLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthcheckQuery, HealthcheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthcheckQuery, HealthcheckQueryVariables>(HealthcheckDocument, options);
        }
export type HealthcheckQueryHookResult = ReturnType<typeof useHealthcheckQuery>;
export type HealthcheckLazyQueryHookResult = ReturnType<typeof useHealthcheckLazyQuery>;
export type HealthcheckQueryResult = Apollo.QueryResult<HealthcheckQuery, HealthcheckQueryVariables>;